
















import { Component, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import CompanyForm from './components/CompanyForm.vue'
import ViewModel from '../../models/ViewModel'

@Component({
  components: {
    Widget,
    CompanyForm,
  },
})
export default class CompanyEdit extends ViewModel {
  @Prop()
  public id!: string;

  public mode: string = 'station';

  public title: string = 'Create Company';

  public mounted() {
    if (this.user.isSuperAdmin) {
      this.mode = 'entity'
    }
    if (this.id) {
      this.title = `Edit ${this.mode}`
    } else {
      this.title = `Create ${this.mode}`
    }
  }
}
