

































































































import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Company from '@/models/Company'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import ViewModel from '../../models/ViewModel'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
  },
})
export default class CompanyHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement;

  public company: Company = new Company();

  public query: string[] = [];

  public page_size: number = 25;

  public sort_by: string = 'name';

  public sort_desc: boolean = false;

  public page: number = 1;

  public records: number = 0;

  public loading: boolean = false;

  public modal: any = {
    delete: false,
  };

  public fields: any = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
      show: true,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      show: true,
    },
    {
      key: 'parent_id',
      label: 'Parent/Owner',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'created_at',
      label: 'Created At',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'updated_at',
      label: 'Update At',
      sortable: true,
      class: 'text-center',
      show: false,
    },
    {
      key: 'action',
      label: 'Action',
      sortable: false,
      show: true,
    },
  ];

  public query_settings: any = {
    company_fields: [
      {
        name: 'parent',
        key: 'parent_id',
      },
    ],
    custom_fields: [
      {
        name: 'type:advertiser',
        value: 'type:advertiser',
      },
      {
        name: 'type:publisher',
        value: 'type:publisher',
      },
      {
        name: 'type:agency',
        value: 'type:agency',
      },
      {
        name: 'type:station',
        value: 'type:station',
      },
    ],
  };

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public companies(context: any) {
    this.loading = true
    return Company.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.filter,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public confirmDelete(company: Company) {
    this.company = company
    this.modal.delete = true
  }

  public deleteCompany() {
    this.company.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public createCompany() {
    this.$router.push('/app/company')
  }

  public editCompany(id: string) {
    this.$router.push(`/app/company/${id}`)
  }

  public created() {}
}
